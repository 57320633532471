// EXTERNAL_DATA
export const externalDocs = [
  {
    id: 'broker_contract_notes',
    label: 'Broker Contract Notes'
  },
  {
    id: 'broker_contract_note_formats',
    label: 'Broker Contract Note Formats'
  },
  {
    id: 'import_email_contract_note',
    label: 'Import Email Contract Note'
  },
  {
    id: 'import_stapled_security_exceptions',
    label: 'Import Stapled Security Exceptions'
  },
  {
    id: 'import_user_prices',
    label: 'Import User Market Prices'
  },
  {
    id: 'import_user_securities',
    label: 'Import User Securities'
  },
  {
    id: 'import_user_security_distributions',
    label: 'Import User Security Distributions'
  },
  {
    id: 'import_user_transactions',
    label: 'Import User Transactions'
  },
  {
    id: 'import_user_parcel_picks',
    label: 'Import User Parcel Picks'
  },
  {
    id: 'auto_build_stm_client_transfer',
    label: 'Import STM Autobuild Client'
  }
];

// USER CHOICES
export const userChoiceDocs = [
  {
    id: 'participation_settings',
    label: 'Participation Settings'
  },

  {
    id: 'coop_action_choices_filter',
    label: 'Coop Action Choices Filters'
  },
  {
    id: 'bonus_coop_action_choices',
    label: 'Coop Action Choices: Bonus'
  },
  {
    id: 'rollover_coop_action_choices',
    label: 'Coop Action Choices: Rollover'
  },
  {
    id: 'small_sale_coop_action_choices',
    label: 'Coop Action Choices: Small Sale'
  },
  {
    id: 'normal_dividend_coop_action_choices',
    label: 'Coop Action Choices: Dividend'
  },
  {
    id: 'buyback_dividend_coop_action_choices',
    label: 'Coop Action Choices: Buyback Dividend'
  },
  {
    id: 'update_buyback_units',
    label: 'Update Buyback Units'
  },
  {
    id: 'delete_coop_action_choices',
    label: 'Delete Coop Action Choices'
  },
  {
    id: 'coop_action_choices_export',
    label: 'Export Coop Action Choices'
  }
];

// USER_SECURITIES
export const userSecDocs = [
  {
    id: 'user_security_management',
    label: 'User Security Setup'
  },
  {
    id: 'user_market_prices',
    label: 'User Market Prices'
  },
  {
    id: 'user_distribution',
    label: 'User Distribution'
  }
];

// PORTFOLIO MANAGEMENT
export const pfDocs = [
  {
    id: 'portfolio_setup',
    label: 'Portfolio Setup'
  },
  {
    id: 'portfolio_processing',
    label: 'Portfolio Processing'
  },
  {
    id: 'move_portfolio',
    label: 'Move Portfolio'
  },
  {
    id: 'delete_portfolio',
    label: 'Delete Portfolio'
  },
  {
    id: 'portfolio_filters',
    label: 'Portfolio Filters'
  },
  {
    id: 'portfolio_preferences',
    label: 'Portfolio Preferences'
  }
];

// REGISTRY
export const registryDocs = [
  {
    id: 'holder_setup',
    label: 'Create Holder'
  },
  {
    id: 'delete_holder',
    label: 'Delete Holder'
  },
  {
    id: 'download_registry_data',
    label: 'Download Registry Data'
  },
  {
    id: 'registry_configurations',
    label: 'Registry Configurations'
  }
];

// TRANSACTIONS
export const transactionDocs = [
  {
    id: 'purchase_transaction',
    label: 'Transaction Entry: Purchase'
  },
  {
    id: 'purchase_transaction_share_placement_plan',
    label: 'Transaction Entry: Purchase (Share Placement/Share Purchase Plan)'
  },
  {
    id: 'sale_transaction',
    label: 'Transaction Entry: Sale'
  },
  {
    id: 'rights_transaction',
    label: 'Transaction Entry: Exercise Of Right'
  },
  {
    id: 'options_transaction',
    label: 'Transaction Entry: Exercise Of Option'
  },
  {
    id: 'payment_of_call_transaction',
    label: 'Transaction Entry: Payment Of Call'
  },
  {
    id: 'capital_adjustment_transaction',
    label: 'Transaction Entry: Capital Adjustment'
  },
  {
    id: 'prior_year_losses_transaction',
    label: 'Transaction Entry: Prior Year Losses'
  },
  {
    id: 'delete_transaction',
    label: 'Delete Transaction'
  },
  {
    id: 'import_user_transactions',
    label: 'Import User Transactions'
  },
  {
    id: 'user_transactions_export',
    label: 'Export User Transactions'
  }
];

// REPORTS
export const reportDocs = [
  {
    id: 'activity_report',
    label: 'Activity'
  },
  {
    id: 'cost_change_report',
    label: 'Cost Change'
  },
  {
    id: 'income_report',
    label: 'Income'
  },
  {
    id: 'realised_report',
    label: 'Realised'
  },
  {
    id: 'registry_data_report',
    label: 'Registry Data'
  },
  {
    id: 'unrealised_report',
    label: 'Unrealised'
  }
];

// PROFILE_MANAGEMENT
export const profileDocs = [
  {
    id: 'change_password',
    label: 'Change password'
  },
  {
    id: 'enable_disable_mfa',
    label: 'Enable/Disable MFA'
  },
  {
    id: 'user_log_out',
    label: 'Log Out'
  },
  {
    id: 'update_profile',
    label: 'Update profile details'
  }
];

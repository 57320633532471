// USER_GROUP_MANAGEMENT
export const userGroupMgmtDocs = [
  {
    id: 'user_group_setup',
    label: 'User Group Setup'
  },
  {
    id: 'user_group_user_mapping',
    label: 'User Group: User Mapping'
  },
  {
    id: 'user_group_portfolio_mapping',
    label: 'User Group: Portfolio Mapping'
  }
];

// ACCESS MANAGEMENT
export const accessMgmtDocs = [
  {
    id: 'create_users',
    label: 'Create Users'
  },
  {
    id: 'update_disable_user',
    label: 'Update/Disable User'
  },
  {
    id: 'manage_billing',
    label: 'Manage Billing'
  },
  {
    id: 'upload_logo',
    label: 'Upload Logo'
  },
  {
    id: 'user_sessions',
    label: 'User Session Management'
  },
  {
    id: 'steps_taken_authApp_lost',
    label: 'Auth App Lost Steps'
  },
  {
    id: 'sso_login_feature',
    label: 'SSO Login Feature'
  },
  {
    id: 'sso_setup',
    label: 'SSO Setup'
  }
];

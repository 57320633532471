// CLIENT SETUP
export const clientDocs = [
  {
    id: 'client_setup',
    label: 'Client Setup'
  },
  {
    id: 'client_contact_setup',
    label: 'Client Contact Setup'
  }
];
